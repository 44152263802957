import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VDialog,{attrs:{"max-width":"700"},model:{value:(_vm.dialogs.replyEmail),callback:function ($$v) {_vm.$set(_vm.dialogs, "replyEmail", $$v)},expression:"dialogs.replyEmail"}},[_c(VCard,{staticClass:"mx-auto"},[_c('send-email',{attrs:{"to":_vm.email,"subject":_vm.replySubject(_vm.replyData),"userId":_vm.replyData.user.uid},on:{"sent":_vm.onEmailSend,"cancel":function($event){_vm.dialogs.replyEmail = false}},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" Reply to User's Message via Email "),_c(VSpacer),_c(VBtn,{attrs:{"color":"white","icon":""},on:{"click":function($event){_vm.dialogs.replyEmail = false}}},[_c(VIcon,[_vm._v("mdi-close")])],1)]},proxy:true}])})],1)],1),_c(VDialog,{attrs:{"max-width":"600"},model:{value:(_vm.dialogs.replyHistory),callback:function ($$v) {_vm.$set(_vm.dialogs, "replyHistory", $$v)},expression:"dialogs.replyHistory"}},[_c(VCard,{staticClass:"mx-auto"},[_c(VCardTitle,[_vm._v(" Reply History ")]),_c(VCardText,[_c(VExpansionPanels,{attrs:{"value":0,"outlined":""}},_vm._l((_vm.replyHistory),function(m,i){return _c(VExpansionPanel,{key:i},[_c(VExpansionPanelHeader,[_vm._v(" "+_vm._s(m.subject)+" ")]),_c(VExpansionPanelContent,[_c('p',{domProps:{"innerHTML":_vm._s(m.html)}}),(m._timestamps)?_c('p',[_c('date-hover',{attrs:{"bottom":"","date":m._timestamps.created}})],1):_vm._e()])],1)}),1)],1)],1)],1),_c('div',[_c(VRadioGroup,{on:{"change":_vm.handleFilter},model:{value:(_vm.filter.message),callback:function ($$v) {_vm.$set(_vm.filter, "message", $$v)},expression:"filter.message"}},[_c(VRadio,{attrs:{"value":"all","label":"All"}}),_c(VRadio,{attrs:{"value":"new","label":"New"}}),_c(VRadio,{attrs:{"value":"seen","label":"Seen"}})],1)],1),_c(VDataTable,{attrs:{"items":_vm.messages,"headers":_vm.headers,"sort-by":"timestamp","sort-desc":true},scopedSlots:_vm._u([{key:"header.type",fn:function(ref){
var header = ref.header;
return [_vm._v(_vm._s(header.text)+" "),_c('table-filter',{attrs:{"items":_vm.CONTACT_TYPES},model:{value:(_vm.filter.type),callback:function ($$v) {_vm.$set(_vm.filter, "type", $$v)},expression:"filter.type"}})]}},{key:"item.timestamp",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.formatDate(item.timestamp)))]}},{key:"item.replied_at",fn:function(ref){
var item = ref.item;
return [_c('div',{on:{"click":function($event){return _vm.showReplyHistory(item)}}},[_c('span',{staticClass:"date-replied"},[_vm._v(" "+_vm._s(_vm.formatDate(item.replied_at))+" ")])])]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_c('div',[(_vm.getContactType(item.type))?_c(VChip,{attrs:{"small":"","color":_vm.getContactType(item.type).style.color || 'primary'}},[_vm._v(" "+_vm._s(_vm.getContactType(item.type).text)+" ")]):_vm._e()],1)]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [(item.reply)?_c(VBtn,{attrs:{"icon":""},on:{"click":function($event){return _vm.replyEmail(item)}}},[_c(VImg,{attrs:{"max-width":"30","src":require("../../assets/img/Icons/sendgrid.png")}})],1):_vm._e(),(item.reply)?_c(VBtn,{staticClass:"ml-2 mb-1",attrs:{"target":"_blank","href":("mailto:" + (item.user.email)),"text":"","small":""}},[_c(VImg,{attrs:{"src":require("../../assets/img/Icons/gmail.png"),"max-width":"30"}}),_c(VIcon,[_vm._v("mdi-reply")])],1):_vm._e(),(!item.seen)?_c(VBtn,{staticClass:"ml-2 text-none",attrs:{"loading":_vm.loadings.markButton,"color":"success","small":""},on:{"click":function($event){return _vm.markAsRead(item)}}},[_vm._v("Read"),_c(VIcon,[_vm._v("mdi-check-all")])],1):_vm._e()]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }