<template>
  <div>
    <v-dialog v-model="dialogs.replyEmail" max-width="700">
      <v-card class="mx-auto">
        <send-email
          :to="email"
          :subject="replySubject(replyData)"
          :userId="replyData.user.uid"
          @sent="onEmailSend"
          @cancel="dialogs.replyEmail = false"
        >
          <template v-slot:title>
            Reply to User's Message via Email
            <v-spacer></v-spacer>
            <v-btn color="white" icon @click="dialogs.replyEmail = false"
              ><v-icon>mdi-close</v-icon></v-btn
            >
          </template>
        </send-email>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogs.replyHistory" max-width="600">
      <v-card class="mx-auto">
        <v-card-title> Reply History </v-card-title>
        <v-card-text>
          <v-expansion-panels :value="0" outlined>
            <v-expansion-panel v-for="(m, i) in replyHistory" :key="i">
              <v-expansion-panel-header>
                {{ m.subject }}
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <p v-html="m.html"></p>
                <p v-if="m._timestamps">
                  <date-hover bottom :date="m._timestamps.created"></date-hover>
                </p>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-card-text>
      </v-card>
    </v-dialog>
    <div>
      <v-radio-group @change="handleFilter" v-model="filter.message">
        <v-radio value="all" label="All"></v-radio>
        <v-radio value="new" label="New"></v-radio>
        <v-radio value="seen" label="Seen"></v-radio>
      </v-radio-group>
    </div>
    <v-data-table
      :items="messages"
      :headers="headers"
      sort-by="timestamp"
      :sort-desc="true"
    >
      <template v-slot:[`header.type`]="{ header }"
        >{{ header.text }}
        <table-filter
          v-model="filter.type"
          :items="CONTACT_TYPES"
        ></table-filter>
      </template>

      <template v-slot:[`item.timestamp`]="{ item }">{{
        formatDate(item.timestamp)
      }}</template>
      <template v-slot:[`item.replied_at`]="{ item }">
        <div @click="showReplyHistory(item)">
          <span class="date-replied">
            {{ formatDate(item.replied_at) }}
          </span>
        </div>
      </template>
      <template v-slot:[`item.type`]="{ item }">
        <div>
          <v-chip
            v-if="getContactType(item.type)"
            small
            :color="getContactType(item.type).style.color || 'primary'"
          >
            {{ getContactType(item.type).text }}
          </v-chip>
        </div>
      </template>
      <template v-slot:[`item.action`]="{ item }" class="ma-3">
        <v-btn icon @click="replyEmail(item)" v-if="item.reply">
          <v-img
            max-width="30"
            src="../../assets/img/Icons/sendgrid.png"
          ></v-img>
        </v-btn>
        <v-btn
          target="_blank"
          v-if="item.reply"
          :href="`mailto:${item.user.email}`"
          class="ml-2 mb-1"
          text
          small
        >
          <v-img src="../../assets/img/Icons/gmail.png" max-width="30"></v-img>
          <v-icon>mdi-reply</v-icon>
        </v-btn>
        <v-btn
          v-if="!item.seen"
          :loading="loadings.markButton"
          color="success"
          @click="markAsRead(item)"
          class="ml-2 text-none"
          small
          >Read<v-icon>mdi-check-all</v-icon></v-btn
        >
      </template>
    </v-data-table>
  </div>
</template>

<script>
import admin from '@/mixins/admin'
import utils from '@/mixins/utils'
import Moment from 'moment'
import SendEmail from './SendEmail.vue'
import { contactRef } from '../../firebase/db'
import { mapGetters, mapMutations } from 'vuex'
import selection from '../../mixins/selection'
export default {
  mixins: [admin, utils, selection],
  components: {
    SendEmail: SendEmail,
    DateHover: () => import('../../components/Core/DateHover.vue'),
    TableFilter: () => import('@/components/Core/TableFilter.vue')
  },
  data: () => ({
    headers: [
      { text: 'Message', value: 'message' },
      { text: 'Sender', value: 'user.email' },
      { text: 'Received', value: 'timestamp' },
      { text: 'Replied', value: 'replied_at' },
      { text: 'Type', value: 'type' },
      { text: 'Action', value: 'action', width: '160' }
    ],
    group: null,
    MAX_STR_LEN: 200,
    filters: {
      all: false,
      unseen: true,
      seen: false
    },
    // filter: 'new',
    filter: {
      message: 'new'
    },
    detail: {},
    dialogs: {
      detail: false,
      replyEmail: false,
      replyHistory: false
    },
    email: '',
    replyData: { user: {} },
    loadings: {
      markButton: false
    },

    replyHistory: [],
    unsubscribe: null
  }),
  computed: {
    ...mapGetters({
      CONTACT_TYPES: 'selection/CONTACT_TYPES'
    }),
    messages: {
      get() {
        let res = this.copyObject(this.$store.getters['admin/messages'])
        if (this.filter.type) {
          res = res.filter(e => e.type == this.filter.type)
        }
        res = res.map(e => {
          e.timestamp = this.formatTimestamp(e.timestamp, 'std')
          e.replied = this.formatTimestamp(e.replied, 'std')
          return e
        })
        return res
      },
      set(data) {
        this.setMessages(data)
      }
    }
  },
  async mounted() {
    await this.updateMessages()
  },
  beforeDestroy() {
    this.unsubscribe()
  },
  methods: {
    ...mapMutations({
      setMessages: 'admin/setMessages'
    }),
    async getMessages({ unseen, seen }) {
      let data = contactRef

      if (seen) {
        data = data.where('seen', '==', true)
      } else if (unseen) {
        data = data.where('seen', '==', false)
      }

      this.unsubscribe = data.onSnapshot(res => {
        let data = []
        res.forEach(e => {
          data.push({ ...e.data(), id: e.id })
        })
        this.messages = data
      })
    },
    messageDetail(message) {
      this.detail = message
      this.dialogs.detail = true
    },
    async updateMessages() {
      this.getMessages({ seen: this.filters.seen, unseen: this.filters.unseen })
    },
    formatDate(ts) {
      let date = this.parseTimestamp(ts)
      if (!date) {
        return ''
      }
      return Moment(date).format('lll')
    },
    handleFilter() {
      this.filters = { all: false, new: false, seen: false }
      if (this.filter.message == 'all') {
        this.filters.all = true
      }
      if (this.filter.message == 'new') {
        this.filters.unseen = true
      }
      if (this.filter.message == 'seen') {
        this.filters.seen = true
      }
      this.updateMessages()
    },
    async markAsRead(item) {
      await this.changeMessageStatus(item, true)
      this.updateMessages()
    },
    replyEmail(item) {
      this.email = item.user.email
      this.replyData = item
      this.dialogs.replyEmail = true
    },
    replySubject(data) {
      let d = this.formatTimestamp(data.timestamp, 'll')
      let res = `RE: Your message from `
      res += `${String(d)}`
      return res
    },
    onEmailSend(message) {
      this.dialogs.replyEmail = false
      let data = this.copyObject(this.replyData)
      data.replied_at = new Date()
      let id = data.id
      delete data.id

      if (!data.replyMessages) {
        data.replyMessages = []
      }
      data.replyMessages.push({ ...message, timestamp: new Date() })
      contactRef.doc(id).set(data)
    },
    showReplyHistory(data) {
      if (data.replyMessages) {
        this.replyHistory = data.replyMessages.reverse()
      }
      this.dialogs.replyHistory = true
    }
  }
}
</script>

<style lang="sass" scoped>
@import "../../design/_colors.scss"
.date-replied
  &:hover
    color: $color-primary
</style>
